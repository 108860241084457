import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Aerial";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Τα εναέρια πανιά είναι μια από τις τέχνες του τσίρκου, ένας συνδυασμός
          ακροβατικών και χορού στον αέρα.
        </P>
        <P>
          Στο μάθημα αποκτάς δύναμη σε όλο σου το σώμα. Δε χρειάζεται να έχεις
          δύναμη ή ευλυγισία για να ξεκινήσεις, χτίζονται σταδιακά μέσα από τα
          μαθήματα.
        </P>
        <P>
          Είναι μια μαγική εμπειρία το να χορεύεις στον αέρα που σίγουρα θέλεις
          να τη ζήσεις!
        </P>
      </Section.Main>
    </Layout>
  );
}
